<template>
    <div :data-id="item.id">
        <v-skeleton-loader
            v-if="!item.name"
            type="card"
            width="150"
            height="188"
            class="mt-2"
        >
        </v-skeleton-loader>

        <div class="mini-item mini-wizard"
             :class="{'maturing':item.wizarre.mature_on}"
             v-if="item.name"
             :title="tooltipText(item)"
             v-title
        >
            <div class="item-hover"></div>
            <div class="item-box" :class="{'kinship-penalty': hasPenalty}">
                <div class="item-header">
                <span>
                    {{ item.name || '#' + item.id }}
                </span>
                </div>

                <div class="item-content">
                    <img :src="item.thumbnail_100" class="thumbnail-img"/>
                    <icon-element :element="element_1" img-size="26px" class="element-icon"></icon-element>
                    <icon-element :element="element_2" v-if="element_2" img-size="26px"
                                  class="element-icon second-element-icon"></icon-element>
                    <div class="item-badge" v-if="item.wizarre.hasOwnProperty('souls_remaining')">
                        {{ item.wizarre.souls_remaining }}/{{ item.wizarre.souls_max }}
                    </div>
                    <div v-if="item.wizarre.mature_on"
                         class="item-level d-flex align-center"
                    >
                        <span>0</span>
                        <v-progress-linear
                            :value="maturationProgress"
                            background-color="#38294a"
                            class="item-experience-bar"
                            color="#fff"
                        ></v-progress-linear>
                    </div>
                    <div v-else
                         class="item-level d-flex align-center"
                    >
                        <span>1</span>
                        <v-progress-linear
                            class="item-experience-bar"
                            color="#fff"
                            background-color="#38294a"
                            :value="0"
                        ></v-progress-linear>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
import IconElement from "../../icon-element";

export default {
    components: {IconElement},
    props: {
        item: Object,
        hasPenalty: {
            type: Boolean,
            default: false,
        }
    },
    data() {
        return {}
    },
    created() {
    },
    computed: {
        element_1() {
            if (this.item.wizarre.elements && this.item.wizarre.elements[0]) {
                return this.item.wizarre.elements[0];
            }
        },
        element_2() {
            if (this.item.wizarre.elements && this.item.wizarre.elements[1]) {
                return this.item.wizarre.elements[1];
            }
        },
        maturationProgress() {
            const bornTimestamp = new Date(this.item.created_on).getTime();
            const matureTimestamp = new Date(this.item.wizarre.mature_on).getTime();
            const nowTimestamp = Date.now();

            const maturationPeriod = matureTimestamp - bornTimestamp;
            const maturationProgress = nowTimestamp - bornTimestamp;

            return Math.round((maturationProgress / maturationPeriod) * 100) || 1;
        },
    },
    methods: {
        tooltipText(item) {
            if (item.wizarre?.mature_on) {
                return 'Mature on: ' + (new Date(item.wizarre.mature_on)).toLocaleString();
            }

            return null;
        },
    }
};
</script>

<style lang="scss" scoped>
.mini-item {
    .item-box.kinship-penalty {
        @include bg-card-red;

        &:hover {
            @include bg-card-red-hover;

            .item-badge {
                @include bg-card-red-soul-hover;
            }
        }

        .item-badge {
            @include bg-card-red-soul;
        }
    }
}

.element-icon {
    top: 62.5px;
    left: 5px;
    position: absolute;
}

.second-element-icon {
    top: 87.5px;
}

.item-level {
    width: 60%;
    left: 20px;
    bottom: 0;
    position: absolute;

    span {
        display: block;
        text-align: center;
        width: 20px;
    }

    .item-experience-bar {
        width: 55%;
        margin: 0 0 0 2px;
    }
}

.maturing {
    img.thumbnail-img {
        height: 100px;
        max-height: 100px;
        margin: 70px 0 0 0;
    }
}
</style>
