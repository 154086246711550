var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { "data-id": _vm.item.id } },
    [
      !_vm.item.name
        ? _c("v-skeleton-loader", {
            staticClass: "mt-2",
            attrs: { type: "card", width: "150", height: "188" },
          })
        : _vm._e(),
      _vm.item.name
        ? _c(
            "div",
            {
              directives: [{ name: "title", rawName: "v-title" }],
              staticClass: "mini-item mini-wizard",
              class: { maturing: _vm.item.wizarre.mature_on },
              attrs: { title: _vm.tooltipText(_vm.item) },
            },
            [
              _c("div", { staticClass: "item-hover" }),
              _c(
                "div",
                {
                  staticClass: "item-box",
                  class: { "kinship-penalty": _vm.hasPenalty },
                },
                [
                  _c("div", { staticClass: "item-header" }, [
                    _c("span", [
                      _vm._v(
                        " " + _vm._s(_vm.item.name || "#" + _vm.item.id) + " "
                      ),
                    ]),
                  ]),
                  _c(
                    "div",
                    { staticClass: "item-content" },
                    [
                      _c("img", {
                        staticClass: "thumbnail-img",
                        attrs: { src: _vm.item.thumbnail_100 },
                      }),
                      _c("icon-element", {
                        staticClass: "element-icon",
                        attrs: { element: _vm.element_1, "img-size": "26px" },
                      }),
                      _vm.element_2
                        ? _c("icon-element", {
                            staticClass: "element-icon second-element-icon",
                            attrs: {
                              element: _vm.element_2,
                              "img-size": "26px",
                            },
                          })
                        : _vm._e(),
                      _vm.item.wizarre.hasOwnProperty("souls_remaining")
                        ? _c("div", { staticClass: "item-badge" }, [
                            _vm._v(
                              " " +
                                _vm._s(_vm.item.wizarre.souls_remaining) +
                                "/" +
                                _vm._s(_vm.item.wizarre.souls_max) +
                                " "
                            ),
                          ])
                        : _vm._e(),
                      _vm.item.wizarre.mature_on
                        ? _c(
                            "div",
                            { staticClass: "item-level d-flex align-center" },
                            [
                              _c("span", [_vm._v("0")]),
                              _c("v-progress-linear", {
                                staticClass: "item-experience-bar",
                                attrs: {
                                  value: _vm.maturationProgress,
                                  "background-color": "#38294a",
                                  color: "#fff",
                                },
                              }),
                            ],
                            1
                          )
                        : _c(
                            "div",
                            { staticClass: "item-level d-flex align-center" },
                            [
                              _c("span", [_vm._v("1")]),
                              _c("v-progress-linear", {
                                staticClass: "item-experience-bar",
                                attrs: {
                                  color: "#fff",
                                  "background-color": "#38294a",
                                  value: 0,
                                },
                              }),
                            ],
                            1
                          ),
                    ],
                    1
                  ),
                ]
              ),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }